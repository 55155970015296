import React from "react";
import {Trans, useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import {graphql} from 'gatsby';
import {StaticImage} from "gatsby-plugin-image";
import {returnLocaleURL} from "../../../utils/utils";
import {createMarkup} from "../../../utils/utils";
import Solution from "../../../components/solutions/Solution";
import ElectronicLockLineup from "../../../components/solutions/ElectronicLockLineup";

import CarouselWrapper from '../../../components/carousel/CarouselWrapper';
import Project01 from './images/project/03-2-project-01a.jpg';
import Project02 from './images/project/03-2-project-02a.jpg';
import Project03 from './images/project/03-2-project-03a.jpg';
import Project04 from './images/project/03-2-project-04a.jpg';
import Project05 from './images/project/03-2-project-05a.jpg';
import Project06 from './images/project/03-2-project-06a.jpg';
import Project07 from './images/project/03-2-project-07a.jpg';
import Project08 from './images/project/03-2-project-08a.jpg';
import Project09 from './images/project/03-2-project-09a.jpg';
import staffLocker from './images/applications/staff-locker.jpg';
import inRoom from './images/applications/hotel-room.jpg';
import spa from './images/applications/spa.jpg';
import CustomerVideo from "../../../components/solutions/CustomerVideo";
import posterSolution from "./images/poster-hospitality.jpg";


const IndexPage = () => {

  const {t} = useTranslation();
  const context = React.useContext(I18nextContext);
  let sl = context.language;
  const projectImages = [
    {
      original: Project01,
      originalAlt: 'Armani Hotel Room',
      thumbnailAlt: 'Armani Hotel Room thumbnail',
      thumbnail: Project01,
      thumbnailLabel: 'Armani Hotel',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project02,
      originalAlt: 'Digilock 4G Locks in the Spa at the Mirage',
      thumbnailAlt: 'Digilock 4G Locks in the Spa at the Mirage thumbnail',
      thumbnail: Project02,
      thumbnailLabel: 'The Mirage',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project03,
      originalAlt: 'Ibis Hotel keypad lockers',
      thumbnailAlt: 'Ibis Hotel keypad lockers thumbnail',
      thumbnail: Project03,
      thumbnailLabel: 'Ibis Hotel',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project05,
      originalAlt: 'Adare Manor keypad lockers ',
      thumbnailAlt: 'Adare Manor keypad lockers thumbnail',
      thumbnail: Project05,
      thumbnailLabel: 'Adare Manor',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project06,
      originalAlt: 'Ananti Club keypad lockers',
      thumbnailAlt: 'Ananti Club keypad lockers thumbnail',
      thumbnail: Project06,
      thumbnailLabel: 'Ananti Club',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project07,
      originalAlt: 'Great Northern Golf keypad lockers',
      thumbnailAlt: 'Great Northern Golf keypad lockers thumbnail',
      thumbnail: Project07,
      thumbnailLabel: 'Great Northern Golf',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project08,
      originalAlt: 'Yas Links keypad lockers',
      thumbnailAlt: 'Yas Links keypad lockers thumbnail',
      thumbnail: Project08,
      thumbnailLabel: 'Yas Links',
      productLink: returnLocaleURL(sl, '/products/')
    },
    {
      original: Project09,
      originalAlt: 'Greenville Country Club Locker Room with Aspire Locks Installed',
      thumbnailAlt: 'Greenville Country Club Locker Room with Aspire Locks Installed thumbnail',
      thumbnail: Project09,
      thumbnailLabel: 'Greenville Country Club',
      productLink: returnLocaleURL(sl, '/products/')
    }
  ];
  const projectAsides = [
    '<span>united arab emirates</span><br>' +
    '<h2>Armani Hotel</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Axis Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>The Mirage</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>the netherlands</span><br>' +
    '<h2>Ibis Hotel</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Sola Keypad</a></p>',

    '<span>Ireland</span><br>' +
    '<h2>Adare Manor</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Axis Keypad</a></p>',

    '<span>korea</span><br>' +
    '<h2>Ananti Club</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock Axis Keypad</a></p>',

    '<span>denmark</span><br>' +
    '<h2>Great Northern Golf</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>united arab emirates</span><br>' +
    '<h2>Yas Links</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>',

    '<span>united states</span><br>' +
    '<h2>Greenville Country Club</h2>' +
    '<p><a href="' + returnLocaleURL(sl, '/products/') + '">Digilock 4G Keypad</a></p>'
  ];
  const ProjectAside = _ => <aside className={'dynamic-aside'}
                                   dangerouslySetInnerHTML={createMarkup(projectAsides[0])}/>;
  const solutions = [
    {
      img: staffLocker,
      label: 'retail_locker_rooms',
      alt: 'staff_locker_rooms',
      bg: 'light'
    },
    {
      img: inRoom,
      label: 'in_room_storage',
      alt: 'in_room_storage',
      bg: 'light'
    },
    {
      img: spa,
      label: 'spa_fitness_center',
      alt: 'spa_fitness_center',
      bg: 'dark'
    }
  ]

  return (
      <Layout>
        <Seo title={t('solutions_hospitality')}
             description={t('des_solutions_hospitality')}/>
        <div className="solutions">
          <section className="hero --hero-image no-pad">
            <StaticImage
                src="../../../images/industries/hero-hospitality.jpg"
                quality={100}
                layout={'fullWidth'}
                formats={["auto", "webp", "avif"]}
                alt="Hospitality"
            />
            <h1>
              <Trans>
                hospitality
              </Trans>
              <span>
                <Trans>
                  hospitality_hero_subhead
                </Trans>
              </span>
            </h1>
          </section>
          <section className="customer-video healthcare">
            <div className="container">
              <div className={'grid-container'}>
                <h2><Trans>hospitality_subhero_title</Trans></h2>
                <ul>
                  <li><Trans>hospitality_subhero_list_l1</Trans></li>
                  <li><Trans>hospitality_subhero_list_l2</Trans></li>
                  <li><Trans>hospitality_subhero_list_l3</Trans></li>
                  <li><Trans>hospitality_subhero_list_l4</Trans></li>
                  <li><Trans>hospitality_subhero_list_l5</Trans></li>
                  <li><Trans>hospitality_subhero_list_l6</Trans></li>
                </ul>
              </div>
              <div className="video-holder">
                <StaticImage
                    src="./images/hospitality.jpg"
                    quality={100}
                    width={508}
                    height={312}
                    formats={["auto", "webp", "avif"]}
                    loading={'lazy'}
                    alt="hospitality"
                />
              </div>
            </div>
          </section>
          <ElectronicLockLineup label={'hospitality_lineup_label'} showAspire={true} showVersa={true} showOrbit={true} showCTA={true} />
          <section className="industry-carousel">
            <div className="container">
              <h2>
                <Trans>
                  hospitality_carousel
                </Trans>
              </h2>
              <CarouselWrapper
                  items={projectImages}
                  asides={projectAsides}
                  projectAside={<ProjectAside/>}
                  showThumbnails={true}
                  showBullets={true}
                  dynamicAside={true}
                  allowImageClick={false}
              />
            </div>
          </section>
          <section className="customer-video healthcare">
            <CustomerVideo
                poster={posterSolution}
                title={'hospitality_customer_video_title'}
                copy={'hospitality_customer_video_copy'}
                vidURL={'https://cdn.mediavalet.com/usva/digilock/HT1R9yACkEaUOWrRvcbu7w/HxlFOPnZVEqWlJ7zT4S9jQ/Widescreen720p/hospitality.mp4'}
            />
          </section>
          <section className="industry-uses">
            <div className="container">
              <h2><Trans>popular_keyless</Trans></h2>
              <Solution items={solutions}/>
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
